/* WarrantyForm.css */

.warrantyFormDiv {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    max-width: 500px;
    margin: 40px auto;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
}

form label {
    display: block;
    margin: 15px 0;
    color: #555;
}

form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

button {
    display: block;
    width: 100%;
    padding: 10px 15px;
    background-color: #007BFF;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}
