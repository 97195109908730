.customer-list {
    display: flex;
    flex-direction: column;
  }
  
  .customer-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .customer-row span {
    margin-left: 10px;
    flex: 1;
    text-align: center;
  }
  