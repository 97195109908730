.overviewContainer {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.tabMenu {
    display: flex;
    gap: 10px;
    border-bottom: 2px solid #e2e2e2;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.tabMenu button {
    padding: 8px 20px;
    border: 1px solid #d0d0d0; /* Added a subtle border */
    cursor: pointer;
    background-color: #f7f7f7; /* Slightly darker background color for non-active tabs */
    color: #333;               /* Darker text color for non-active tabs */
    transition: background-color 0.3s ease, color 0.3s ease; /* Added color transition */
    border-radius: 4px;
    outline: none;             /* Remove the focus outline for aesthetics */
}

.tabMenu button:hover {
    background-color: #e2e2e2; /* Darker hover effect */
}

.tabMenu .active {
    background-color: #007BFF;
    color: #ffffff;
    border: 1px solid #007BFF; /* Active tab border color same as background */
}

.tabContent {
    padding: 20px;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
}
