.projOverviewDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
}

table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}

thead {
    background-color: #f5f5f5;
}

thead tr {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9rem;
}

th, td {
    padding: 12px 15px;
    border: 1px solid #e0e0e0;
    text-align: left;
}

tbody tr:hover {
    background-color: #f6f6f6;
    cursor: pointer;
}

tbody td {
    font-size: 0.9rem;
}

/* Message styles */
p {
    background-color: #fafafa;
    padding: 10px 15px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    width: 100%;
    text-align: center;
    margin-top: 20px;
}
