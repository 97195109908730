.projOverviewDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    background-color: #f8f8f8;
}

.formContainer {
    width: 100%;
    margin-top: 20px;
}

.formTitle {
    text-align: center;
    color: #333;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.formField {
    display: flex;
    flex-direction: column;
    gap: 15px; 
}

.formLabel {
    display: block;
    color: #666;
    margin-bottom: 5px;
    font-weight: 600;
}

input[type="text"] {
    padding: 10px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 0.9rem;
    width: 100%;
    transition: border-color 0.2s ease-in-out;
}

input[type="text"]:focus {
    border-color: #007BFF;
    outline: none;
}

.pofButton {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.pofButton:hover {
    background-color: #0056b3;
}
