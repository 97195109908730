.overview-flex-container {
  display: flex;
  flex-wrap: wrap;  /* Allow items to wrap to next line */
  gap: 20px;        /* Space between each item */
  justify-content: space-between; /* Space out items evenly */
}

.overview-flex-item {
  flex: 1; /* Allows each item to flexibly fill the space */
  min-width: calc(33.33% - 20px); /* Roughly 1/3 of the container width minus the gap */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 5px;
  background-color: #f8f8f8;
}

/* Responsive styles */
@media (max-width: 768px) {
  .overview-flex-item {
      min-width: calc(50% - 20px); /* 2 items per row on medium screens */
  }
}

@media (max-width: 480px) {
  .overview-flex-item {
      min-width: 100%; /* 1 item per row on small screens */
  }
}
