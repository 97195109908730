.marketingOverview {
    font-family: Arial, sans-serif;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    background-color: #f8f8f8;
}

.tabSection {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.tabButton {
    padding: 10px 15px;
    background-color: #e0e0e0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.tabButton:hover {
    background-color: #d0d0d0;
}

.tabButton.active {
    background-color: #007BFF;
    color: #ffffff;
}

.tabContent {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 5px;
}
