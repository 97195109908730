.quadrants {
    width: 100vw;
    height: 100vh;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 100vh;
    width: 100vw;
    gap: 10px;
    box-sizing: border-box;
    padding: 20px;
  }
  
  .quadrant {
    background-color: #f4f4f4;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .projectOverviewDiv {
    display: flex;
    flex-direction: column;
  }
  